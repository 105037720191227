import React from 'react';
import { Link } from 'react-router-dom';
import './userInfo.less';
import { formatDate } from '../../utils/formatDate';
import { routeEndpoint } from '../../services/api';

const UserInfo = props => {
  const env = process.env.NODE_ENV || 'prod';
  const environment =
    env === 'prod'
      ? 'https://admin.kkb-fp-einfuehrung.ch/'
      : 'http://116.203.73.127/';
  const { userData, userId } = props;

  // console.log('userData', userData);

  const formatedBirthDate = formatDate(userData.birthDate);
  const formatedentranceDate = userData.groupList.date
    ? formatDate(userData.groupList.date)
    : '';
  const formatedlaunchDate = formatDate(userData.launchDate);
  const mailToLink = `mailto:${userData.email},?Subject=AXPO - Loginformationen&Body=Guten Tag, 
  %0D%0DHier finden Sie Ihre Logininformationen: %0DBenutzername: ${userData.username} %0DPasswort: ${userData.plainPassword} %0D%0DVielen Dank %0DIhr AXPO Team`;

  return (
    <div>
      <div className="row">
        <div className="col-md-11">
          <h4 className="ax-user-title">
            {userData.surname} {userData.name}
          </h4>
        </div>
        <div className="col-md-1">
          <Link
            className="ax-btn-icon"
            to={`${routeEndpoint}users/${userId}/userGroup`}
          >
            <i className="fa fa-users m-t-15 fs-30 fa-3x ax-fa-btn m-l-10" />
            <h6 className="ax-title">Bearbeiten </h6>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <h6 className="ax-middle-title">Infos zur Person</h6>
          <p className="ax-title">
            <span className="">Geb.Datum: </span> {formatedBirthDate}
          </p>
          <p className="ax-title">
            <span className="">Funktion: </span> {userData.position}
          </p>
          <p className="ax-title">
            <span className="">Firma: </span> {userData.company}
          </p>
          <p className="ax-title">
            <span className="">Auftrag: </span> {userData.task}
          </p>
          <p className="ax-title">
            Email:{' '}
            <a href={`mailto:${userData.email}`}>
              <span className=""></span> {userData.email}
            </a>
          </p>
          <p className="ax-title">
            Telefon:{' '}
            <a href={`tel:${userData.phone}`.replace(/ /g, '')}>
              {userData.phone}
            </a>
          </p>
        </div>
        <div className="col-md-4">
          <h6 className="ax-middle-title">Informationen Zutritt</h6>
          <p className="ax-title">
            <span className="">Zutritt ab:</span> {formatedentranceDate}
          </p>
          <p className="ax-title">
            <span className="">Einführungsdatum:</span> {formatedlaunchDate}
          </p>
          <p className="ax-title">
            <span className="">Zone: </span>
            {userData.zone === true ? 'ja' : 'nein'}
          </p>
          <p className="ax-title">
            <span className="">Gruppe:</span> {userData.groupList.groupName}
          </p>
          <p className="ax-title">
            <span className="">Sachbearbeiter:</span> {userData.sachbearbeiter}
          </p>
        </div>
        <div className="col-md-4">
          <h6 className="ax-middle-title">Logindaten</h6>
          <p className="ax-title">
            <span className="">Benutzername:</span> {userData.username}
          </p>
          <p className="ax-title">
            <span className="">Passwort:</span> {userData.plainPassword}
          </p>
          <div>
            <a className="ax-btn-icon" href={mailToLink}>
              <i className="fa fa-envelope m-t-15 fs-30 fa-3x ax-fa-btn" />
            </a>
            <a
              href={`${environment}axpoapp/assets/employees/details/${userData.pdfLink}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-file m-l-30 m-t-15 fa-3x ax-fa-btn " />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
