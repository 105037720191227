import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../style/main.less';
import './header.less';
import logo from '../../../assets/images/logo-axpo.png';
import { logout, getUserData } from '../../services/auth.service';
import { routeEndpoint } from '../../services/api';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarVisible: false,
      isProfileVisible: false,
      userRole: '',
      userData: {},
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.userLogout = this.userLogout.bind(this);
    this.profileDropdownClick = this.profileDropdownClick.bind(this);
    this.profileDropdownOutsideClick = this.profileDropdownOutsideClick.bind(
      this
    );
  }

  componentDidMount() {
    const userData = getUserData();
    // console.log(userData);
    this.setState({ userRole: userData.role, userData });
  }

  componentWillUnmount() {
    document.removeEventListener(
      'click',
      this.profileDropdownOutsideClick,
      false
    );
  }

  toggleSidebar = () => {
    const { isSidebarVisible } = this.state;
    this.setState({ isSidebarVisible: !isSidebarVisible });
    const body = document.getElementById('ax-body');
    const pageSidebar = document.getElementById('ax-page-sidebar');
    let timer;
    if (isSidebarVisible) {
      pageSidebar.classList.remove('visible');
      timer = setTimeout(() => {
        body.classList.remove('sidebar-open');
      }, 10);
    } else {
      clearTimeout(timer);
      body.classList.add('sidebar-open');
      setTimeout(() => {
        pageSidebar.classList.add('visible');
      }, 200);
    }
  };

  userLogout = () => {
    logout();
    const { history } = this.props;
    history.push(`${routeEndpoint}login`);
  };

  profileDropdownClick() {
    const { isProfileVisible } = this.state;
    if (!isProfileVisible) {
      document.addEventListener(
        'click',
        this.profileDropdownOutsideClick,
        false
      );
    } else {
      document.removeEventListener(
        'click',
        this.profileDropdownOutsideClick,
        false
      );
    }
    this.setState(prevState => ({
      isProfileVisible: !prevState.isProfileVisible,
    }));
  }

  profileDropdownOutsideClick(e) {
    if (this.node.contains(e.target)) {
      return;
    }
    this.profileDropdownClick();
  }

  render() {
    const { isProfileVisible, userRole, userData } = this.state;
    return (
      <div className="header ax-header">
        {/* START MOBILE SIDEBAR TOGGLE */}
        <button
          type="button"
          onClick={this.toggleSidebar}
          className="btn-link toggle-sidebar ax-toggle-bar"
          data-toggle="sidebar"
        >
          <i className="fa fa-bars" />
        </button>
        {/* <div> */}
        <div className="brand inline m-l-10">
          <Link to={`${routeEndpoint}`}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        {/* </div> */}
        <div className="d-flex align-items-center">
          <div className="pull-right p-r-10 fs-14 font-heading d-lg-block d-none">
            {userRole === 'fpk' ? (
              <span className="ax-header-title">Fremdpersonalkoordinator</span>
            ) : (
              <span className="ax-header-title">
                Sachbearbeiter ({userData.name}
                &nbsp;{userData.surname})
              </span>
            )}
          </div>
          <div
            id="profileDropdown"
            ref={node => {
              this.node = node;
            }}
            className="dropdown pull-right d-lg-block"
          >
            <button
              id="profileDropdownBtn"
              className="profile-dropdown-toggle"
              type="button"
              onClick={this.profileDropdownClick}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded={isProfileVisible}
            >
              <div className="circle ax-circle">
                {userRole === 'fpk' ? (
                  <span className="bold">FPK</span>
                ) : (
                  <span className="bold">SB</span>
                )}
              </div>
            </button>
            <div
              className={`dropdown-menu dropdown-menu-right profile-dropdown ${
                isProfileVisible ? 'show' : ''
              }`}
              role="menu"
            >
              <button
                type="button"
                onClick={this.userLogout}
                className="clearfix bg-master-lighter dropdown-item"
              >
                <span className="pull-left">Ausloggen</span>
                <span className="pull-right">
                  <i className="pg-power" />
                </span>
              </button>
            </div>
          </div>
          {/* <!-- END User Info--> */}
        </div>
      </div>
    );
  }
}
export default Header;
