/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import toaster from '../../utils/toaster';
import './listModules.less';
import { saveModules } from '../../services/users.service';

class ListModules extends Component {
  constructor(props) {
    super(props);
    const { modules } = this.props;
    this.state = {
      allModules: modules,
      filteredList: [],
      assignedModules: modules.filter(module => module.isAssigned === true),
    };
    this.filterModules = this.filterModules.bind(this);
  }

  componentDidMount() {
    this.setFilteredList();
  }

  setFilteredList() {
    const { userRole } = this.props;
    const { allModules, assignedModules } = this.state;
    if (userRole === 'Sachbearbeiter') {
      this.setState({ filteredList: allModules });
    } else {
      this.setState({ filteredList: assignedModules });
    }
  }

  filterModules = event => {
    const searchedValue = event.target.value.toLowerCase();
    const { allModules, assignedModules } = this.state;
    const { userRole } = this.props;
    const listToFilter =
      userRole === 'Sachbearbeiter' ? allModules : assignedModules;
    if (searchedValue === '') {
      this.setFilteredList();
    } else {
      const filteredModules = listToFilter.filter(item => {
        return item.moduleName.toLowerCase().includes(searchedValue);
      });
      this.setState({ filteredList: filteredModules });
    }
  };

  toogleActive = item => {
    const { allModules } = this.state;
    allModules.forEach(module => {
      if (module.moduleId === item.moduleId) {
        module.isAssigned = !module.isAssigned;
      }
    });
    this.setState({ allModules, filteredList: allModules });
  };

  onSave = () => {
    const { match } = this.props;
    const { userId } = match.params;
    const { allModules } = this.state;
    saveModules(userId, allModules).then(
      () => {
        toaster('Module werden erfolgreich gespeichert!', 'success');
      },
      () => {
        toaster('Ein Fehler ist aufgetreten!', 'error');
      }
    );
  };

  render() {
    const { filteredList, allModules } = this.state;
    const { userRole } = this.props;

    return (
      <React.Fragment>
        <div className="m-t-40">
          <h6 className="ax-middle-title">Module</h6>
          {userRole === 'Sachbearbeiter' && (
            <p className="ax-empty-msg">
              Das Modul ist aktiv, wenn die Box rot ausgefüllt ist. Sollte das
              Modul nur einen roten Rahmen haben ist es nicht aktiv.
            </p>
          )}
        </div>
        <div className="input-group m-t-10">
          <form>
            <label htmlFor="searchModules" className="ax-search-label">
              Suchen:
            </label>
            <input
              type="text"
              className="form-control ax-search"
              onChange={this.filterModules}
              id="searchModules"
              placeholder="Suchen"
            />
          </form>
        </div>
        <div className="row m-t-20 ax-module-grid">
          {allModules.length === 0 || filteredList.length === 0 ? (
            <div className="col-md-12">
              <div className="jumbotron jumbotron-fluid d-flex justify-content-center ax-empty">
                {allModules.length === 0 ? (
                  <p className="ax-empty-msg m-t-40">
                    Die Modulliste ist leer.
                  </p>
                ) : (
                  <p className="ax-empty-msg m-t-40">
                    Keine passenden Module gefunden.
                  </p>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
          {userRole === 'Sachbearbeiter'
            ? filteredList.map(item => {
                return (
                  <div
                    className="col-lg-2 col-md-4 col-sm-6"
                    key={item.moduleId}
                  >
                    <button
                      type="button"
                      className={`ax-module-item  ${
                        item.isAssigned ? 'active' : ''
                      }`}
                      onClick={this.toogleActive.bind(this, item)}
                    >
                      {item.moduleName}
                    </button>
                  </div>
                );
              })
            : filteredList.map(item => {
                return (
                  <div
                    key={item.moduleId}
                    className="col-lg-2 col-md-4 col-sm-6"
                  >
                    <div className="ax-module-item ax-module-item--plain">
                      {item.moduleName}
                    </div>
                  </div>
                );
              })}
        </div>
        {userRole === 'Sachbearbeiter' ? (
          <div className="row m-t-30">
            <div className="col-md ax-save">
              <button
                type="button"
                onClick={this.onSave}
                className="btn btn-brand btn-cons"
              >
                Speichern
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

export default ListModules;
