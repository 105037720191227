import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { formatDate, formatTime } from '../../utils/formatDate';
import { routeEndpoint } from '../../services/api';
// import { getUserData } from '../../services/auth.service';
import './classList.less';

class ClassList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // userRole: '',
      startDate: new Date(),
      startTime: new Date(),
      form: {
        date: '',
        time: '',
      },
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  // componentDidMount() {
  //   const userData = getUserData();
  //   this.setState({ userRole: userData.role });
  // }

  handleDateChange(dt) {
    const { form } = this.state;
    const date = formatDate(dt);
    this.setState({
      startDate: dt,
      form: {
        ...form,
        date,
      },
    });
  }

  handleTimeChange(tm) {
    const { form } = this.state;
    const time = formatTime(tm);
    this.setState({
      startTime: tm,
      form: {
        ...form,
        time,
      },
    });
  }

  render() {
    const { startDate, startTime } = this.state;

    return (
      <div>
        <h4 className="ax-header-text">Import Klassenliste</h4>

        <React.Fragment>
          <p className="ax-middle-text">
            Sie finden links die Rubrik Fremdpersonal. Dort sehen Sie diejenigen
            Fremdpersonen, die vom Sachbearbeiter angemeldet wurden. Die
            Lernmodule werden über eine APP mit personalisiertem Login der
            Fremdperson zur Verfügung gestellt. Diese Login-Daten sind dem
            Fremdpersonal bereits zugestellt worden.
          </p>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  Gruppenname <span className="mg-required-label" />
                </label>
                <input
                  type="text"
                  name="numberOfKeys"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Datum Von</label>
                <div className="mg-datepicker input-group date p-l-0">
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={date => this.handleDateChange(date)}
                    showDisabledMonthNavigation
                    dateFormat="dd.MM.yyyy"
                  />
                  <div className="input-group-append ">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Zeit des Besuches</label>
                <div className="mg-datepicker mg-datepicker-width input-group date p-l-0">
                  <div className="time-filter">
                    <DatePicker
                      className="form-control"
                      selected={startTime}
                      onChange={time => this.handleTimeChange(time)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat="H:mm"
                      timeFormat="HH:mm"
                      timeCaption="Zeit"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>
                  Vollstandiger Gruppenname{' '}
                  <span className="mg-required-label" />
                </label>
                <input
                  type="text"
                  name="numberOfKeys"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>
                  CSV List <span className="mg-required-label" />
                </label>
                <input
                  type="text"
                  name="numberOfKeys"
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row m-t-30 ">
            <div className="col-md-4">
              {/* {userResults.decision === 'Zulass gewähren' ||
                userResults.decision === 'Zulass ablehnen' ? (
                  ''
                ) : ( */}
              {/* <button
                type="button"
                className="btn btn-complete btn-cons"
                // onClick={this.onSave}
              >
                Hochladen
              </button> */}
              <Link
                className="btn btn-brand btn-cons"
                to={`${routeEndpoint}classList/classListTable`}
              >
                Hochladen
              </Link>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default ClassList;
