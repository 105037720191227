import { fetch } from './api';

export const getAllUsers = offset => {
  return new Promise((resolve, reject) => {
    fetch
      .get('/all/femployees', {
        headers: {
          offset,
        },
      })
      .then(users => {
        // console.log(users);
        return resolve(users);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};
export const getGroups = () => {
  return new Promise((resolve, reject) => {
    fetch
      .get('/groupList')
      .then(groups => {
        return resolve(groups);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};

export const getCompanies = () => {
  return new Promise((resolve, reject) => {
    fetch
      .get('/companies')
      .then(companies => {
        return resolve(companies);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};

export const filterTable = (data, offset) => {
  return new Promise((resolve, reject) => {
    fetch
      .post('/filterTable', data, {
        offset,
      })
      .then(filters => {
        return resolve(filters);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};

export const searchTable = (findBy, offset) => {
  return new Promise((resolve, reject) => {
    fetch
      .post('/find', { offset, findBy })
      .then(filters => {
        return resolve(filters);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};

export const getGroupList = id => {
  return new Promise((resolve, reject) => {
    fetch
      .get(`/grouplist/${id}`)
      .then(user => {
        return resolve(user);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};

export const createUser = data => {
  return new Promise((resolve, reject) => {
    fetch
      .post(`/usercreate`, data)
      .then(response => {
        console.log(response);
        return resolve(response);
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const getUser = id => {
  return new Promise((resolve, reject) => {
    // console.log('ALDIONNNN');
    fetch
      .get(`/femployee/${id}`)
      .then(user => {
        return resolve(user);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};

export const getUserResults = (userId, attemptId) => {
  return new Promise((resolve, reject) => {
    fetch
      .get(`/femployee/${userId}/result/${attemptId}`)
      .then(result => {
        return resolve(result);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};

export const wrongQuestionResults = (userId, attemptId) => {
  return new Promise((resolve, reject) => {
    fetch
      .get(`/wrongQuestions/${userId}/result/${attemptId}`)
      .then(result => {
        return resolve(result);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};

export const changeGroup = (id, classListID) => {
  return new Promise((resolve, reject) => {
    fetch
      .put('/femployee/changegroup', {
        employeeId: id,
        groupListId: classListID,
      })
      .then(res => {
        return resolve(res);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};

export const saveModules = (id, allModules) => {
  return new Promise((resolve, reject) => {
    fetch
      .put('/femployee/modules', { userId: id, moduleList: allModules })
      .then(res => {
        return resolve(res);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};

export const updateUser = (id, data) => {
  return new Promise((resolve, reject) => {
    fetch
      .put(`/updateFemployee/${id}`, data)
      .then(res => {
        return resolve(res);
      })
      .catch(error => {
        console.log('DDSSSS', error);
        reject(error.errors);
      });
  });
};

export const deleteUser = userId => {
  return new Promise((resolve, reject) => {
    fetch
      .post('/delete/femployee', {
        userId,
      })
      .then(res => {
        return resolve(res);
      })
      .catch(error => {
        reject(error.errors);
      });
  });
};

export const saveDecisionComment = (empId, mod, resId, dec, com) => {
  return new Promise((resolve, reject) => {
    fetch
      .post('/femployee/result/response', {
        employeeId: empId,
        modules: mod,
        resultId: resId,
        decision: dec,
        comment: com,
      })
      .then(res => {
        return resolve(res);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};
