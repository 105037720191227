import React from 'react';
import './footer.less';

const Footer = () => (
  <footer className="k-footer">
    <span className="axpo-footer-text">
      Copyright &copy; {new Date().getFullYear()} AXPO. Alle Rechte vorbehalten.
    </span>
    <a
      href="https://www.axpo.com/ch/en/about-us/privacy-policy.html"
      target="_blank"
      rel="noopener noreferrer"
      className="privacy"
    >
      Privacy policy | Axpo
    </a>
    <a
      href="https://www.axpo.com/ch/en/about-us/impressum.html"
      target="_blank"
      rel="noopener noreferrer"
      className="impress"
    >
      Impressum | Axpo
    </a>
  </footer>
);

export default Footer;
