// eslint-disable-next-line import/prefer-default-export
import React from 'react';
import { Link } from 'react-router-dom';
// import { routeEndpoint } from '../services/api';

export const checkDecision = decision => {
  if (decision === 'Zulass gewähren' || decision === 'Zulass ablehnen') {
    return decision;
  }
  return 'pendent';
};

const env = process.env.NODE_ENV || 'prod';
const environment =
  env === 'prod'
    ? 'https://admin.kkb-fp-einfuehrung.ch/'
    : 'http://116.203.73.127/';

export const checkPDF = (decision, pdf) => {
  if (
    pdf === undefined ||
    pdf === null ||
    pdf === '' ||
    decision !== 'Zulass gewähren'
  ) {
    return '-';
  }
  return (
    <Link
      target="_blank"
      to={{
        pathname: `${environment}axpoapp/assets/employees/result/${pdf}`,
      }}
    >
      Link zum PDF
    </Link>
  );
};

export const checkButton = (decision, url, resId) => {
  if (decision === 'Zulass gewähren' || decision === 'Zulass ablehnen') {
    return (
      <Link className="btn btn-brand" to={`${url}/${resId}`}>
        Ansehen
      </Link>
    );
  }
  return (
    <Link className="btn btn-brand" to={`${url}/${resId}`}>
      Bearbeiten
    </Link>
  );
};
