import React, { Component } from 'react';
import Loader from '../../utils/loader';
import { login } from '../../services/auth.service';
import { routeEndpoint } from '../../services/api';
import './login.less';

class LogIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      saveCredentials: false,
      submitted: false,
      loading: false,
      error: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCredentials = this.handleCredentials.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password, saveCredentials } = this.state;
    const trimUsername = username.trim();
    const trimPassword = password.trim();
    // stop here if form is invalid
    if (!(trimUsername && trimPassword)) {
      return;
    }

    this.setState({ loading: true });
    login(trimUsername, trimPassword, saveCredentials).then(
      () => {
        const { history } = this.props;
        history.push(routeEndpoint);
      },
      error => this.setState({ error, loading: false })
    );
  }

  handleCredentials() {
    const { saveCredentials } = this.state;
    this.setState({ saveCredentials: !saveCredentials });
  }

  render() {
    const {
      username,
      password,
      saveCredentials,
      submitted,
      loading,
      error,
    } = this.state;
    return (
      <form
        id="form-login"
        className="p-t-15"
        name="form"
        onSubmit={this.handleSubmit}
        noValidate
      >
        {/* <!-- START Form Control--> */}
        {error && !loading && <div className="alert alert-danger">{error}</div>}
        <div
          className={`form-group form-group-default ${
            submitted && !username ? ' has-error' : ''
          }`}
        >
          <label htmlFor="username">
            <span className="ax-login-info">Benutzername</span>
            <div className="controls">
              <input
                id="username"
                type="text"
                name="username"
                placeholder="Benutzername"
                className="form-control"
                value={username}
                autoComplete="off"
                onChange={this.handleChange}
                required
              />
            </div>
          </label>
        </div>
        {submitted && !username && (
          <p id="username-error" className="error">
            Benutzername ist erforderlich.
          </p>
        )}
        {/* <!-- END Form Control--> */}
        {/* <!-- START Form Control--> */}
        <div
          className={`form-group form-group-default ${
            submitted && !password ? ' has-error' : ''
          }`}
        >
          <label htmlFor="password">
            <span className="ax-login-info">Passwort</span>
            <div className="controls">
              <input
                id="password"
                type="password"
                className="form-control"
                name="password"
                placeholder="Passwort"
                value={password}
                onChange={this.handleChange}
                autoComplete="off"
                required
              />
            </div>
          </label>
        </div>
        {submitted && !password && (
          <p id="username-error" className="error">
            Passwort wird benötigt.
          </p>
        )}
        {/* <!-- START Form Control--> */}
        <div className="row">
          <div className="col-md-8 col-sm-8 col-xs-9 no-padding sm-p-l-15 checkbox-wrapper">
            <div className="checkbox check-success">
              <input
                name="saveCredentials"
                id="checkbox1"
                type="checkbox"
                checked={saveCredentials}
                onChange={this.handleCredentials}
              />
              <label className="ax-save-buttontext" htmlFor="checkbox1">
                Anmeldedaten speichern
              </label>
            </div>
          </div>
          <div className="col-md-4 col-sm-4 col-xs-3 d-flex align-items-center justify-content-end question-wrapper">
            <a
              href="http://www.prismavideo.ch/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-info small"
            >
              Unterstützung?
            </a>
          </div>
        </div>
        {loading && (
          <div>
            <Loader />
          </div>
        )}
        {/* <!-- END Form Control--> */}
        <button
          className="btn btn-danger btn-cons m-t-10 btn-brand ax-anmelde-text"
          disabled={loading || username.length === 0 || password.length === 0}
          type="submit"
        >
          Anmelden
        </button>
      </form>
    );
  }
}

export default LogIn;
