import React, { Component } from 'react';

import UserInfo from '../../components/userInfo/userInfo';
import ListModules from '../../components/listModules/listModules';
import UserResults from '../../components/userResults/userResults';
import { getUserData } from '../../services/auth.service';
import { getUser } from '../../services/users.service';

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: '',
      user: null,
      userId: '',
    };
  }

  componentDidMount() {
    const userData = getUserData();
    this.setState({ userRole: userData.role });
    const { match } = this.props;
    getUser(match.params.userId).then(user => {
      this.setState({ user, userId: match.params.userId });
    });
  }

  render() {
    const { userRole, user, userId } = this.state;
    return (
      <React.Fragment>
        {user ? (
          <React.Fragment>
            <UserInfo userData={user} userId={userId} />
            <ListModules
              userRole={userRole}
              modules={user.modules}
              {...this.props}
            />
          </React.Fragment>
        ) : (
          ''
        )}
        {userRole === 'fpk' ? <UserResults {...this.props} /> : ''}
      </React.Fragment>
    );
  }
}

export default UserEdit;
