/* eslint-disable */

import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Typeahead } from 'react-bootstrap-typeahead';
import de from 'date-fns/locale/de';
import toaster from '../../utils/toaster';
import { required } from '../../validations/validations';
import {
  getGroups,
  createUser,
  getCompanies,
} from '../../services/users.service';
import { newformatUSDateWithLine } from '../../utils/formatDate';
import { routeEndpoint } from '../../services/api';
import './userCreate.less';

registerLocale('de', de);

class UserCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // userRole: '',
      birthDate: '',
      launchDate: new Date(),
      groupNames: [],
      companies: [],
      form: {
        name: '',
        surname: '',
        company: '',
        groupList: '',
        position: '',
        sachbearbeiter: '',
        birthDate: new Date(),
        newbirthDate: new Date(),
        launchDate: new Date(),
        task: '',
        phone: '',
        email: '',
        defaultGrupenName: '',
        zone: '',
      },
      validations: {
        name: [required],
        surname: [required],
        company: [required],
        // position: [required],
        sachbearbeiter: [required],
        task: [required],
        // email: [required],
        // phone: [required],
      },
      error: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateField = this.validateField.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTypeaheadChange = this.handleTypeaheadChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    this.getAllGroupNames();
    this.getAllCompanies();
  }

  getAllGroupNames() {
    getGroups().then(groups => {
      const { form } = this.state;
      const findDefault = groups.allGr.find(
        item => item.groupName === '_Default'
      );
      this.setState({
        groupNames: groups.allGr,
        form: {
          ...form,
          defaultGrupenName: findDefault,
        },
      });
    });
  }

  getAllCompanies() {
    getCompanies().then(companies => {
      this.setState({ companies: companies.allC });
    });
  }

  validateField = (fieldName, fieldValue) => {
    let errorMessage;
    const { form, validations, error } = this.state;
    const validationArray = validations[fieldName];
    this.setState({
      error: {
        ...error,
        [fieldName]: '',
      },
    });

    for (let i = 0; i < validationArray.length; i++) {
      const validation = validationArray[i];
      if (typeof validation === 'function') {
        errorMessage = validation(fieldValue, form);
        if (errorMessage) {
          this.setState({
            error: {
              ...error,
              [fieldName]: errorMessage,
            },
          });
          break;
        }
      } else {
        this.validateField(validation, form[validation]);
      }
    }
  };

  handleChange(e, fieldName) {
    const fieldValue = e.target.value;
    const { form, validations } = this.state;
    this.setState(
      {
        form: {
          ...form,
          [fieldName]: fieldValue,
        },
      },
      () => {
        if (validations[fieldName]) {
          this.validateField(fieldName, fieldValue);
        }
      }
    );
  }

  handleInputChange(val, fieldName) {
    const { form, validations } = this.state;
    this.setState({ form: { ...form, [fieldName]: val } }, () => {
      if (validations[fieldName]) {
        this.validateField(fieldName, val);
      }
    });
  }

  handleTypeaheadChange(val, fieldName) {
    const fieldValue = val;
    const { form, validations } = this.state;
    if (fieldValue[0] && fieldValue[0].id) {
      this.setState(
        {
          form: {
            ...form,
            [fieldName]: String(fieldValue[0].id),
          },
        },
        () => {
          if (validations[fieldName]) {
            this.validateField(fieldName, fieldValue[0].prename);
          }
        }
      );
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { form } = this.state;

    // form.launchDate =
    //   form.launchDate instanceof Date
    //     ? formatDate(form.launchDate)
    //     : form.launchDate;
    // console.log(form);
    // return;
    createUser(form).then(
      res => {
        toaster(res.message, 'success');
        const { history } = this.props;
        history.push(`${routeEndpoint}users`);
      },
      error => {
        const errors = Object.values(error);
        errors.map(err => {
          return toaster(err, 'error');
        });
        // toaster('Fehler beim Erstellen des Benutzers!', 'error');
      }
    );
  }

  handleDateChange(date, fieldName, birthdd = false) {
    const { form } = this.state;

    this.setState({
      [fieldName]: date,
      form: {
        ...form,
        [fieldName]: date,
      },
    });
    if (birthdd) {
      this.setState({
        form: {
          ...form,
          [birthdd]: newformatUSDateWithLine(date),
        },
      });
    }
  }

  render() {
    const {
      birthDate,
      launchDate,
      groupNames,
      companies,
      form,
      error,
    } = this.state;

    // console.log('SSSS', form);

    const { form: defaultGrupenName } = this.state;
    const companyList = companies.map(company => ({
      id: company._id,
      prename: company.company,
    }));

    return (
      <div>
        <form className="form" onSubmit={this.handleSubmit} noValidate>
          <h4 className="ax-header-text p-b-10">Fremdpersonal anlegen</h4>
          <React.Fragment>
            <div className="row">
              <div className="col-lg-3">
                <div className="form-group">
                  <label>
                    Nachname <span className="mg-required-label">*</span>
                  </label>
                  <input
                    tabIndex="1"
                    type="text"
                    name="surname"
                    className="form-control"
                    placeholder="Nachname eingeben"
                    onChange={e => this.handleChange(e, 'surname')}
                  />
                  <span className="mg-error-message">{error.surname}</span>
                </div>

                <div className="form-group ">
                  <label>
                    Firma <span className="mg-required-label">*</span>
                  </label>
                  <Typeahead
                    inputProps={{ tabIndex: '3' }}
                    id="company"
                    labelKey="prename"
                    emptyLabel="Keine Ergebnisse gefunden"
                    placeholder="Firma eingeben"
                    onInputChange={typed =>
                      this.handleInputChange(typed, 'company')
                    }
                    onChange={selected =>
                      this.handleTypeaheadChange(selected, 'company')
                    }
                    options={companyList}
                  />
                  <span className="mg-error-message">{error.company}</span>
                </div>
                <div className="form-group">
                  <label>
                    Gruppenname <span className="mg-required-label">*</span>
                  </label>
                  <select
                    tabIndex="5"
                    value={defaultGrupenName.defaultGrupenName._id}
                    onChange={e => this.handleChange(e, 'groupList')}
                    className="form-control"
                  >
                    {/* <option
                      value={defaultGrupenName.defaultGrupenName._id}
                      key={defaultGrupenName.defaultGrupenName._id}
                    >
                      {defaultGrupenName.defaultGrupenName.groupName}
                    </option> */}
                    {groupNames
                      ? groupNames.map(({ _id, generatedName }) => {
                          return (
                            <option key={_id} value={_id}>
                              {generatedName}
                            </option>
                          );
                        })
                      : ''}
                  </select>
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    tabIndex="6"
                    name="email"
                    className="form-control"
                    placeholder="Email eingeben"
                    onChange={e => this.handleChange(e, 'email')}
                  />
                  <span className="mg-error-message">{error.email}</span>
                </div>
                <div className="form-group">
                  <label>
                    Einführungsdatum (01.01.2019){' '}
                    <span className="mg-required-label">*</span>
                  </label>
                  <div className="mg-datepicker input-group date p-l-0">
                    <DatePicker
                      tabIndex="8"
                      className="form-control"
                      selected={launchDate}
                      locale="de"
                      onChange={e => this.handleDateChange(e, 'launchDate')}
                      minDate={new Date()}
                      showDisabledMonthNavigation
                      dateFormat="dd.MM.yyyy"
                    />
                    <div className="input-group-append ">
                      <span className="input-group-text">
                        <i className="far fa-calendar-alt" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row form-group col-md-12 p-l-0">
                  <label>Auftrag</label>
                  <div className="form-group form-group-default">
                    <textarea
                      className="form-control"
                      tabIndex="10"
                      name="task"
                      cols="30"
                      rows="10"
                      placeholder="Auftrag eingeben"
                      onChange={e => this.handleChange(e, 'task')}
                    />
                  </div>
                  <span className="mg-error-message">{error.task}</span>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="form-group">
                  <label>
                    Vorname <span className="mg-required-label">*</span>
                  </label>
                  <input
                    type="text"
                    tabIndex="2"
                    name="name"
                    className="form-control"
                    placeholder="Vorname eingeben"
                    onChange={e => this.handleChange(e, 'name')}
                  />
                  <span className="mg-error-message">{error.name}</span>
                </div>
                <div className="form-group">
                  <label>Funktion</label>
                  <input
                    type="text"
                    name="position"
                    tabIndex="4"
                    className="form-control"
                    placeholder="Funktion eingeben"
                    onChange={e => this.handleChange(e, 'position')}
                  />
                  <span className="mg-error-message">{error.position}</span>
                </div>
                <div className="form-group">
                  <label>
                    Sachbearbeiter Name;Vorname{' '}
                    <span className="mg-required-label">*</span>
                  </label>
                  <input
                    type="text"
                    tabIndex="5"
                    name="sachbearbeiter"
                    className="form-control"
                    placeholder="Sachbearbeiter eingeben"
                    onChange={e => this.handleChange(e, 'sachbearbeiter')}
                  />
                  <span className="mg-error-message">
                    {error.sachbearbeiter}
                  </span>
                </div>
                <div className="form-group">
                  <label>Telefon</label>
                  <input
                    type="number"
                    tabIndex="7"
                    name="phone"
                    className="form-control"
                    placeholder="Telefon eingeben"
                    onChange={e => this.handleChange(e, 'phone')}
                  />
                  <span className="mg-error-message">{error.phone}</span>
                </div>
                <div className="form-group">
                  <label>
                    Geburtsdatum (01.01.2019)
                    <span className="mg-required-label">*</span>
                  </label>
                  <div className="mg-datepicker input-group date p-l-0">
                    <DatePicker
                      tabIndex="9"
                      className="form-control"
                      selected={birthDate}
                      locale="de"
                      // disabled={isPreviewMode}
                      onChange={e =>
                        this.handleDateChange(e, 'birthDate', 'newbirthDate')
                      }
                      showDisabledMonthNavigation
                      dateFormat="dd.MM.yyyy"
                    />
                    <div className="input-group-append ">
                      <span className="input-group-text">
                        <i className="far fa-calendar-alt" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    ZONE <span className="mg-required-label">*</span>
                  </label>
                  <div className="mg-radio radio radio-success">
                    <input
                      checked={form.zone === true}
                      tabIndex="11"
                      type="radio"
                      onChange={() => {
                        this.setState({
                          form: { ...form, zone: true },
                        });
                      }}
                      value="yes"
                      name="ZONE"
                      id="yes"
                    />
                    <label htmlFor="yes">Ja</label>
                    <input
                      checked={form.zone === false}
                      type="radio"
                      onChange={() => {
                        this.setState({
                          form: { ...form, zone: false },
                        });
                      }}
                      value="no"
                      name="ZONE"
                      id="no"
                    />
                    <label htmlFor="no">Nein</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-t-30">
              <div className="col-md-4">
                <button
                  className="btn btn-brand btn-cons"
                  tabIndex="12"
                  type="submit"
                >
                  Anlegen
                </button>
              </div>
            </div>
          </React.Fragment>
        </form>
      </div>
    );
  }
}

export default UserCreate;
