/* eslint-disable */

import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Typeahead } from 'react-bootstrap-typeahead';
import de from 'date-fns/locale/de';
import './updateGroup.less';
import toaster from '../../utils/toaster';
import { formatDate, formatUSDateWithLine } from '../../utils/formatDate';

import {
  // changeGroup,
  getCompanies,
  getGroups,
  updateUser,
} from '../../services/users.service';
import { routeEndpoint } from '../../services/api';

registerLocale('de', de);

class UpdateGroup extends Component {
  constructor(props) {
    super(props);
    const { userData } = this.props;
    const { _id } = userData.groupList;
    this.state = {
      // userData,
      userGroups: [],
      groupName: _id,
      startDate: new Date(userData.birthDate),
      minimumDate: new Date(userData.launchDate),
      birthDate: formatUSDateWithLine(userData.birthDate),
      launchDate: formatUSDateWithLine(userData.launchDate),
      companies: [],
      form: {
        name: userData.name,
        surname: userData.surname,
        company: userData.company,
        sachbearbeiter: userData.sachbearbeiter,
        birthDate: formatDate(userData.birthDate),
        groupName: userData.groupList.groupName,
        launchDate: formatDate(userData.launchDate),
        task: userData.task,
        phone: userData.phone,
        email: userData.email,
        zone: userData.zone,
        position: userData.position,
      },
    };
  }

  componentDidMount() {
    this.getAllCompanies();
    getGroups().then(groups => {
      this.setState({ userGroups: groups.allGr });
    });
    // this.updateUser();
  }

  getAllCompanies() {
    getCompanies().then(companies => {
      this.setState({ companies: companies.allC });
    });
  }

  onSave = () => {
    const { match } = this.props;
    const { userId } = match.params;
    const { form, birthDate, launchDate } = this.state;

    const prepareForm = form;
    prepareForm.birthDate = birthDate;
    prepareForm.launchDate = launchDate;

    updateUser(userId, form).then(
      () => {
        toaster('Gruppenname wurde erfolgreich ausgewählt!', 'success');
        const { history } = this.props;

        history.push(`${routeEndpoint}users/${userId}`);
      },
      () => {
        toaster('Fehler beim Aktualisieren des Gruppennamens!', 'error');
      }
    );
  };

  handleChange(e) {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
      },
      groupName: e.target.value,
    });
  }

  handleChangeForm(e, name) {
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        [name]: e.target.value,
      },
    });
  }

  handleDateChange(dt, dateProperty) {
    const { form } = this.state;
    const date = formatDate(dt);
    this.setState({
      startDate: dt,
      minimumDate: dt,
      [dateProperty]: formatUSDateWithLine(dt),
      form: {
        ...form,
        [dateProperty]: date,
      },
    });
  }

  handleInputChange(val, fieldName) {
    const { form } = this.state;
    this.setState({ form: { ...form, [fieldName]: val } });
  }

  handleTypeaheadChange(val, fieldName) {
    const fieldValue = val;
    const { form } = this.state;
    if (fieldValue[0] && fieldValue[0].company) {
      this.setState({
        form: {
          ...form,
          [fieldName]: String(fieldValue[0].company),
        },
      });
    }
  }

  render() {
    const {
      userGroups,
      groupName,
      startDate,
      minimumDate,
      form,
      companies,
    } = this.state;
    const companyList = companies.map(company => ({
      id: company._id,
      company: company.company,
    }));

    return (
      <div>
        <React.Fragment>
          <div className="row">
            <div className="col-lg-3">
              <h6 className="ax-middle-title">Infos zur Person</h6>
              <div className="form-group">
                <label>
                  Nachname <span className="mg-required-label">*</span>
                </label>
                <input
                  type="text"
                  name="surname"
                  value={form.surname}
                  tabIndex="1"
                  className="form-control"
                  placeholder="Nachname eingeben"
                  onChange={e => this.handleChangeForm(e, 'surname')}
                />
                {/* <span className="mg-error-message">{error.position}</span> */}
              </div>

              <div className="form-group">
                <label>
                  Firma <span className="mg-required-label">*</span>
                </label>
                <Typeahead
                  inputProps={{ tabIndex: '3' }}
                  defaultInputValue={form.company}
                  id="company"
                  labelKey="company"
                  emptyLabel="Keine Ergebnisse gefunden"
                  placeholder="Firma eingeben"
                  onInputChange={typed =>
                    this.handleInputChange(typed, 'company')
                  }
                  onChange={selected =>
                    this.handleTypeaheadChange(selected, 'company')
                  }
                  options={companyList}
                />
                {/* <span className="mg-error-message">{error.position}</span> */}
              </div>

              <div className="form-group">
                <label>
                  Gruppenname <span className="mg-required-label">*</span>
                </label>
                <select
                  tabIndex="5"
                  value={groupName}
                  onChange={e => this.handleChange(e)}
                  className="form-control"
                >
                  <option value="">Bitte wählen Sie ein Gruppenname aus</option>
                  {userGroups
                    ? userGroups.map(({ _id, generatedName }) => {
                        return (
                          <option key={_id} value={_id}>
                            {generatedName}
                          </option>
                        );
                      })
                    : ''}
                </select>
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  tabIndex="6"
                  name="email"
                  value={form.email}
                  className="form-control"
                  placeholder="Email eingeben"
                  onChange={e => this.handleChangeForm(e, 'email')}
                />
                {/* <span className="mg-error-message">{error.position}</span> */}
              </div>

              <div className="form-group">
                <label>
                  Einführungsdatum (01.01.2019){' '}
                  <span className="mg-required-label">*</span>
                </label>
                <div className="mg-datepicker input-group date p-l-0">
                  <DatePicker
                    tabIndex="8"
                    className="form-control"
                    selected={minimumDate}
                    locale="de"
                    value={form.launchDate}
                    onChange={e => this.handleDateChange(e, 'launchDate')}
                    minDate={new Date()}
                    showDisabledMonthNavigation
                    dateFormat="dd.MM.yyyy"
                  />
                  <div className="input-group-append ">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="row form-group col-md-12 ">
                <label>Auftrag</label>
                <div className="form-group form-group-default">
                  <textarea
                    className="form-control"
                    type="text"
                    tabIndex="10"
                    name="task"
                    value={form.task}
                    cols="30"
                    rows="10"
                    placeholder="Auftrag eingeben"
                    onChange={e => this.handleChangeForm(e, 'task')}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <h6 className="ax-middle-title">Informationen Zutritt</h6>

              <div className="form-group">
                <label>
                  Vorname <span className="mg-required-label">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={form.name}
                  tabIndex="2"
                  className="form-control"
                  placeholder="Vorname eingeben"
                  onChange={e => this.handleChangeForm(e, 'name')}
                />
                {/* <span className="mg-error-message">{error.position}</span> */}
              </div>
              <div className="form-group">
                <label>Funktion</label>
                <input
                  type="text"
                  name="position"
                  value={form.position}
                  tabIndex="4"
                  className="form-control"
                  placeholder="Funktion eingeben"
                  onChange={e => this.handleChangeForm(e, 'position')}
                />
                {/* <span className="mg-error-message">{error.position}</span> */}
              </div>
              <div className="form-group">
                <label>
                  Sachbearbeiter <span className="mg-required-label">*</span>
                </label>
                <input
                  type="text"
                  name="sachbearbeiter"
                  value={form.sachbearbeiter}
                  tabIndex="5"
                  className="form-control"
                  placeholder="Sachbearbeiter eingeben"
                  onChange={e => this.handleChangeForm(e, 'sachbearbeiter')}
                />
                {/* <span className="mg-error-message">{error.position}</span> */}
              </div>
              <div className="form-group">
                <label>Telefon</label>
                <input
                  type="text"
                  name="phone"
                  value={form.phone}
                  tabIndex="7"
                  className="form-control"
                  placeholder="Telefon eingeben"
                  onChange={e => this.handleChangeForm(e, 'phone')}
                />
                {/* <span className="mg-error-message">{error.position}</span> */}
              </div>
              <div className="form-group">
                <label>
                  GebursDatum (01.01.2019){' '}
                  <span className="mg-required-label">*</span>
                </label>
                <div className="mg-datepicker input-group date p-l-0">
                  <DatePicker
                    tabIndex="9"
                    className="form-control"
                    selected={startDate}
                    locale="de"
                    value={form.birthDate}
                    onChange={e => this.handleDateChange(e, 'birthDate')}
                    showDisabledMonthNavigation
                    dateFormat="dd.MM.yyyy"
                  />
                  <div className="input-group-append ">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>
                  ZONE <span className="mg-required-label">*</span>
                </label>
                <div className="mg-radio radio radio-success">
                  <input
                    checked={form.zone === true}
                    tabIndex="11"
                    type="radio"
                    onChange={() => {
                      this.setState({
                        form: { ...form, zone: true },
                      });
                    }}
                    value="yes"
                    name="ZONE"
                    id="yes"
                  />
                  <label htmlFor="yes">Ja</label>
                  <input
                    checked={form.zone === false}
                    type="radio"
                    onChange={() => {
                      this.setState({
                        form: { ...form, zone: false },
                      });
                    }}
                    value="no"
                    name="ZONE"
                    id="no"
                  />
                  <label htmlFor="no">Nein</label>
                </div>
              </div>
            </div>
          </div>

          <div className="row m-t-30 ">
            <div className="col-md-4">
              <button
                className="btn btn-brand btn-cons"
                type="button"
                onClick={this.onSave}
              >
                Speichern
              </button>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default UpdateGroup;
