import React, { Component } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import { getAllUsers, filterTable } from '../../services/users.service';
import { formatDate, formatTime } from '../../utils/formatDate';
import '../../style/main.less';
import './classListTable.less';

class ClassListTable extends Component {
  constructor() {
    super();
    this.state = {
      search: '',
      searchTable: '',
      startDate: new Date(),
      startTime: new Date(),
      users: [],
      form: {
        generatedName: '',
        time: '',
        date: '',
      },
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  componentDidMount() {
    // this.getList();
    this.getUsers();
  }

  getUsers() {
    getAllUsers().then(employees => {
      this.setState({ users: employees });
    });
  }

  getFilterData(data) {
    filterTable(data).then(filteredTable => {
      this.setState({ users: filteredTable });
    });
  }

  handleChange(e) {
    const fieldValue = e.target.value;
    const { form } = this.state;
    this.setState({
      form: {
        ...form,
        generatedName: fieldValue,
      },
    });
    setTimeout();
  }

  handleDateChange(dt) {
    const form = this.state;
    const date = formatDate(dt);
    if (date !== '01.01.1970') {
      this.setState({
        startDate: dt,
        form: {
          ...form,
          date,
        },
      });
    } else {
      this.setState({
        startDate: new Date(),
        form: {
          ...form,
          date: '',
        },
      });
    }
  }

  handleTimeChange(tm) {
    const form = this.state;
    const time = formatTime(tm);
    this.setState({
      startTime: tm,
      form: {
        ...form,
        time,
      },
    });
  }

  render() {
    const {
      // columns,
      users,
    } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <h3 className="page-title">Import Klassenliste</h3>
            <div>
              <p className="ax-middle-text">
                Lorem ipsum dolor sit amet, mel semper mandamus ei, cu error
                exerci postea ius. Eum idque utroque honestatis eu. Ne quo
                docendi comprehensam, ex solum civibus eos. Mea prima maiorum
                ponderum eu. Ius id wisi nemore dicunt, mea oblique nusquam no.
                Posse adversarium signiferumque qui cu.
              </p>
              <p className="ax-middle-text">
                Molestie prodesset cum ad, nostro atomorum cu vel. At pericula
                constituto mei, nec ut alii semper. Cu dicam aeterno dolores
                vix, natum exerci vim ei. Ut minimum repudiare vel, in has odio
                quot mucius. Veri appetere disputationi et vis. Sit deleniti
                gloriatur interesset cu, ei novum dignissim sea.
              </p>
            </div>
          </div>
        </div>

        <div className="row m-b-10 m-t-30 m-r-30">
          <div className="col-xs-12 col-sm-12 col-md-5 table-buttons">
            <div className="row">
              <div className="col-md-12 col-lg-6 select-group" />
            </div>
          </div>
        </div>
        <div className="ax-table-wrapper ax-middle-text">
          <MDBTable responsive bordered>
            <MDBTableHead style={{ background: '#f0f0f0' }}>
              <tr>
                <th>Nachname</th>
                <th>Vorname</th>
                <th>Geburtsdatum</th>
                <th>Firma</th>
                <th>Funktion</th>
                <th>Zutritt ab</th>
                <th>Einführungsdatum</th>
                <th>Zeit</th>
                <th>Gruppe</th>
                <th>Zone</th>
                <th>Auftrag</th>
                <th>BAM</th>
                <th>SEM</th>
                <th>PRM</th>
                <th>SPM</th>
              </tr>
            </MDBTableHead>
            {users[0] ? (
              <MDBTableBody>
                {users.map(user => (
                  <tr key={user._id}>
                    <td>{user.company}</td>
                    <td>{user.name}</td>
                    <td>{user.surname}</td>
                    <td>{user.role}</td>
                    <td>{user.position}</td>
                    <td>{formatDate(user.entranceDate)}</td>
                    <td>{formatDate(user.launchDate)}</td>
                    <td>{user.time}</td>
                    <td>{user.generatedName}</td>
                    <td>{user.zone ? 'Ja' : 'Nein'}</td>
                    <td>{user.task}</td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            ) : (
              <MDBTableBody>
                <tr>
                  <td colSpan="14">Keine Fremdarbeiter vorhanden</td>
                </tr>
              </MDBTableBody>
            )}
          </MDBTable>
          {/* <div className="row"><div className="col-sm-12 col-md-5"><div className="dataTables_info" role="status" aria-live="polite">Showing 1 to 10 of 10 entries</div></div><div className="col-sm-12 col-md-7"><div className="dataTables_paginate"><ul className="pagination"><li className="page-item disabled"><a aria-label="Vorherige" className="page-link page-link"><span>Vorherige</span></a></li><li className="page-item active"><a className="page-link page-link">1 <span className="sr-only">(current)</span></a></li><li className="page-item disabled"><a aria-label="Nächste" className="page-link page-link"><span>Nächste</span></a></li></ul></div></div></div> */}
          <div className="row m-t-30 ">
            <div className="col-md-11">
              <button
                className="btn btn-brand btn-cons"
                type="button"
                onClick={this.onSave}
              >
                Drucken
              </button>
            </div>
            <div className="row ">
              <button
                className="btn btn-brand btn-cons"
                type="button"
                onClick={this.onSave}
              >
                Speichern
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClassListTable;
