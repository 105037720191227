import React from 'react';

import { ToastContainer } from 'react-toastify';

import { Link } from 'react-router-dom';
import Header from '../../components/header/header';
import Menu from '../../components/menu/menu';
import Footer from '../../components/footer/footer';
import Breadcrumbs from '../../components/breadcrumb/breadcrumb';
import { routeEndpoint } from '../../services/api';

import logo from '../../../assets/images/logo-axpo.png';
import './mainLayout.less';

const MainLayout = ({ children }) => {
  const { props } = children;

  return (
    <React.Fragment>
      <nav id="ax-page-sidebar" className="page-sidebar" data-pages="sidebar">
        <div className="sidebar-overlay-slide from-top" id="appMenu" />
        <div className="sidebar-header">
          <Link to={routeEndpoint}>
            <img
              src={logo}
              alt="logo"
              className="logo brand"
              data-src="../../../assets/images/logo-axpo.png"
            />
          </Link>
        </div>
        <Menu />
      </nav>
      <div className="page-container ax-container">
        <Header {...props} />
        {/* START PAGE CONTENT WRAPPER  */}
        <div className="page-content-wrapper">
          <div className="content ax-content">
            <Breadcrumbs {...props} />
            {/* START CONTAINER FLUID  */}
            <div className="container-fluid container-fixed-lg ax-container">
              {/* BEGIN PlACE PAGE CONTENT HERE */}
              {children}
              {/* END PLACE PAGE CONTENT HERE */}
            </div>
            {/* END CONTAINER FLUID */}
            <Footer />
          </div>
          {/* END PAGE CONTENT */}
        </div>
      </div>
      <ToastContainer className="ax-toast-container" />
    </React.Fragment>
  );
};

export default MainLayout;
