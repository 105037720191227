import React, { Component } from 'react';

import UserInfo from '../../components/userInfo/userInfo';
import AttemptResult from '../../components/attemptResult/attemptResult';
import {
  getUser,
  getUserResults,
  wrongQuestionResults,
} from '../../services/users.service';

class UserResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      userResults: '',
      wrongQuestion: '',
    };
  }

  componentDidMount() {
    const { match } = this.props;
    getUser(match.params.userId).then(user => {
      this.setState({ user });
    });
    getUserResults(match.params.userId, match.params.attemptId).then(
      userResults => {
        this.setState({ userResults });
      }
    );
    wrongQuestionResults(match.params.userId, match.params.attemptId).then(
      wrongQuestion => {
        this.setState({ wrongQuestion });
      }
    );
  }

  render() {
    const { user, userResults, wrongQuestion } = this.state;

    return (
      <React.Fragment>
        {user ? (
          <div>
            <UserInfo userData={user} />
            {userResults ? (
              <AttemptResult
                userResults={userResults}
                {...this.props}
                wrongQuestion={wrongQuestion}
              />
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </React.Fragment>
    );
  }
}

export default UserResult;
