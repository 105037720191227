/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';
import Modal from 'react-modal';
// import { CSVLink } from 'react-csv';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
// import UsersColumns from './usersColumns';
import {
  getAllUsers,
  filterTable,
  getGroups,
  searchTable,
  deleteUser,
} from '../../services/users.service';
import { routeEndpoint } from '../../services/api';
import {
  formatDate,
  formatTime,
  formatUSDateWithLine,
} from '../../utils/formatDate';
import '../../style/main.less';
import './users.less';

registerLocale('de', de);
let order = false;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

class Users extends Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      startDate: new Date(),
      startTime: new Date(),
      pageOffset: 0,
      nextOffset: 20,
      paginationType: '',
      msgTable: 'Keine Fremdarbeiter vorhanden',
      pageNr: 1,
      disabled: false,
      defaultGroup: '',
      listGroups: [],
      users: [],
      exportUsers: [],
      search: '',
      form: {},
    };
    this.calculatePercentage = this.calculatePercentage.bind(this);
  }

  componentDidMount() {
    this.getUsers();
    this.getAllGroupNames();
  }

  onSort(event, sortKey) {
    // console.log(sortKey);
    const { users } = this.state;
    // users.sort((a, b) => console.log(a[sortKey]));
    order = !order;
    users.sort((a, b) => {
      return order
        ? a[sortKey]
            .toString()
            .localeCompare(b[sortKey])
            .toString()
        : b[sortKey]
            .toString()
            .localeCompare(a[sortKey])
            .toString();
    });
    this.setState({ users });
  }

  // onSort(event, sortKey) {
  //   console.log(sortKey);
  //   const { users } = this.state;
  //   // users.sort((a, b) => console.log(a[sortKey]));
  //   order = !order;

  //   users.sort((a, b) => {
  //     return order ? a[sortKey] - b[sortKey] : b[sortKey] - a[sortKey];
  //   });
  //   this.setState({ users });
  // }

  getUsers() {
    const { pageOffset, nextOffset } = this.state;
    this.calculateNextTablePage('users', nextOffset);
    getAllUsers(pageOffset).then(employees => {
      this.setState({ users: employees });
    });
    getAllUsers().then(exportData => {
      this.setState({ exportUsers: exportData });
    });
  }

  getAllGroupNames() {
    getGroups().then(groups => {
      this.setState({ listGroups: groups.allGr });
    });
  }

  getSearchedData() {
    const { pageOffset, nextOffset } = this.state;
    this.calculateNextTablePage('search', nextOffset);
    const { search } = this.state;
    searchTable(search, pageOffset).then(searchedTable => {
      console.log('SEARCHED DATA', searchedTable);
      if (searchTable.length) {
        this.setState({
          msgTable: 'Keine übereinstimmenden Aufzeichnungen gefunden',
        });
      }
      this.setState({ users: searchedTable });
    });
  }

  getFilteredData() {
    const { pageOffset, nextOffset } = this.state;
    this.calculateNextTablePage('filter', nextOffset);
    const { form } = this.state;
    filterTable(form, pageOffset).then(filteredTable => {
      this.setState({ users: filteredTable });
    });
  }

  ExportToExcel = () => {
    const { exportUsers } = this.state;
    const d = new Date();
    const month = `0${d.getUTCMonth() + 1}`.slice(-2);
    const day = `0${d.getUTCDate()}`.slice(-2);
    const csvName = `${d.getUTCFullYear()}${month}${day}`;
    const module = 'fremdarbeiter';
    const exportData = exportUsers.map(user => {
      const items = {
        _id: user._id,
        name: user.name,
        surname: user.surname,
        username: user.username,
        birthDate: formatDate(user.birthDate),
        company: user.company,
        position: user.position,
        role: user.role,
        entranceDate: formatDate(user.entranceDate),
        launchDate: formatDate(user.launchDate),
        time: user.time,
        generatedName: user.generatedName,
        zone: String(user.zone),
        task: user.task,
        date:
          formatDate(user.result.date) === '01.01.1970'
            ? ''
            : formatDate(user.result.date),
        decision:
          user.result.decision === 'Pending' ? 'Pendent' : user.result.decision,
        score: String(user.result.score),
      };
      return items;
    });
    const exportToCSV = (apiDataz, fileNamez) => {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const ws = XLSX.utils.json_to_sheet(apiDataz);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileNamez + fileExtension);
    };

    return (
      <button
        type="button"
        className="m-l-10 btn btn-warning btn-export"
        onClick={() => exportToCSV(exportData, `${csvName}_${module}`)}
        disabled={exportUsers.length < 1}
      >
        Export
      </button>
    );
  };

  pageOffset = (checkButton, type) => {
    const { pageOffset, nextOffset, pageNr } = this.state;
    const nextPrevious = new Promise(resolve => {
      if (checkButton === 'next') {
        this.setState({
          pageOffset: pageOffset + 20,
          nextOffset: nextOffset + 20,
          pageNr: pageNr + 1,
        });
        resolve();
      } else if (checkButton === 'previous') {
        this.setState({
          pageOffset: pageOffset - 20,
          nextOffset: nextOffset - 20,
          pageNr: pageNr - 1,
        });
        resolve();
      }
    });
    if (type === 'search') {
      nextPrevious.then(() => {
        this.getSearchedData();
      });
    }
    if (type === 'filter') {
      nextPrevious.then(() => {
        this.getFilteredData();
      });
    }
    if (type === 'users') {
      nextPrevious.then(() => {
        this.getUsers();
      });
    }
  };

  resetGroupName() {
    const { form } = this.state;
    delete form.generatedName;
    if (form.time || form.date) {
      this.setState({
        defaultGroup: '',
      });
      setTimeout(() => this.getFilteredData(), 100);
    } else {
      this.setState({
        defaultGroup: '',
      });
      this.getUsers();
    }
  }

  handleInputChange(e) {
    const fieldValue = e.target.value;
    this.setState({
      search: fieldValue,
    });
    setTimeout(() => this.getSearchedData(), 100);
  }

  handleChange(e) {
    const fieldValue = e.target.value;

    const { form } = this.state;
    if (fieldValue !== '') {
      this.setState({
        defaultGroup: fieldValue,
        form: {
          ...form,
          generatedName: fieldValue,
        },
      });
      setTimeout(() => this.getFilteredData(), 100);
    } else {
      delete form.generatedName;
      if (form.time || form.date) {
        this.setState({
          defaultGroup: '',
        });
        setTimeout(() => this.getFilteredData(), 100);
      } else {
        this.setState({
          defaultGroup: '',
        });
        this.getUsers();
      }
    }
  }

  handleDateChange(date) {
    console.log(date);
    const { form } = this.state;
    const dt = formatUSDateWithLine(date);
    if (date !== null) {
      this.setState({
        startDate: date,
        form: {
          ...form,
          date: dt,
        },
      });
      setTimeout(() => this.getFilteredData(), 100);
    } else {
      delete form.date;
      if (form.time || form.generatedName) {
        this.setState({
          startDate: new Date(),
        });
        setTimeout(() => this.getFilteredData(), 100);
      } else {
        this.setState({
          startDate: new Date(),
        });
        this.getUsers();
      }
    }
  }

  // pa din
  handleTimeChange(time) {
    console.log(time);
    const { form } = this.state;
    const tm = formatTime(time);
    if (time !== null) {
      this.setState({
        startTime: time,
        form: {
          ...form,
          time: tm,
        },
      });
      setTimeout(() => this.getFilteredData(), 100);
    } else {
      delete form.time;
      if (form.date || form.generatedName) {
        this.setState({
          startTime: new Date(),
        });
        setTimeout(() => this.getFilteredData(), 100);
      } else {
        this.setState({
          startTime: new Date(),
        });
        this.getUsers();
      }
    }
  }

  calculatePercentage(score, decision, orange) {
    let result = '';
    if (decision === false && orange === false && score === false) {
      result = <td>-</td>;
    }
    if (decision === 'Zulass gewähren') {
      result = <td className="cl-green">{score}%</td>;
    }
    if (decision === 'Zulass ablehnen') {
      result = <td className="cl-red">{score}%</td>;
    }
    if (decision === 'Pending') {
      if (orange === false) {
        if (score !== false) {
          if (score > 70) {
            result = <td className="cl-light-green">{score}%</td>;
          }
          if (score < 70) {
            result = <td className="cl-light-red">{score}%</td>;
          }
        } else {
          result = <td>-</td>;
        }
      } else {
        result = <td className="cl-orange">{score}%</td>;
      }
    }
    return result;
  }

  calculateNextTablePage(type, nextOffset) {
    const { form, search } = this.state;

    if (type === 'search') {
      searchTable(search, nextOffset).then(users => {
        if (Object.keys(users).length === 0) {
          this.setState({ disabled: true, paginationType: 'search' });
        } else {
          this.setState({ disabled: false, paginationType: 'search' });
        }
      });
    }
    if (type === 'filter') {
      filterTable(form, nextOffset).then(users => {
        if (Object.keys(users).length === 0) {
          this.setState({ disabled: true, paginationType: 'filter' });
        } else {
          this.setState({ disabled: false, paginationType: 'filter' });
        }
      });
    }
    if (type === 'users') {
      getAllUsers(nextOffset).then(users => {
        if (Object.keys(users).length === 0) {
          this.setState({ disabled: true, paginationType: 'users' });
        } else {
          this.setState({ disabled: false, paginationType: 'users' });
        }
      });
    }
  }

  confirmDeleteion() {
    const { users, deleteUsrdId } = this.state;
    deleteUser(deleteUsrdId).then(() => {
      const newTable = users.filter(item => item._id !== deleteUsrdId);
      this.setState({
        users: newTable,
        exportUsers: newTable,
        modalIsOpen: false,
      });
    });
  }

  deleteUser(usrid) {
    this.setState({ modalIsOpen: true, deleteUsrdId: usrid });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const {
      // form,
      listGroups,
      users,
      pageOffset,
      pageNr,
      disabled,
      paginationType,
      search,
      defaultGroup,
      startDate,
      msgTable,
      startTime,
      modalIsOpen,
    } = this.state;

    // console.log(form);

    return (
      <div>
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <h3 className="page-title">Fremdpersonal</h3>
          </div>
        </div>

        <div className="row m-b-10 m-t-30 m-r-30">
          <div className="col-xs-12 col-sm-12 col-md-3 table-buttons p-r-0">
            <div className="row p-l-15">
              <div className="btn-create-container">
                <Link className="btn btn-brand" to={`${routeEndpoint}create`}>
                  Erstellen
                </Link>
              </div>
              <div className="btn-export-container">
                {this.ExportToExcel()}
                {/* <CSVLink
                  data={exportData}
                  headers={UsersColumns}
                  filename={`${csvName}_${module}.csv`}
                  className="m-l-10 btn btn-warning btn-export"
                  disabled={users.length < 1}
                >
                  Export
                </CSVLink> */}
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 p-l-0 p-r-0 col-md-4 filter-options mg-datepicker mg-datepicker-width input-group date">
            <div className="date-filter">
              <DatePicker
                className="form-control m-l-0"
                selected={startDate}
                strictParsing
                isClearable
                fixedHeight
                locale="de"
                // maxDate={new Date()}
                // placeholderText="Wählen Sie ein Datum"
                onChange={date => this.handleDateChange(date)}
                showDisabledMonthNavigation
                dateFormat="dd.MM.yyyy"
              />
            </div>
            <div className="time-filter">
              <DatePicker
                className="form-control m-l-10"
                selected={startTime}
                strictParsing
                isClearable
                fixedHeight
                timeFormat="HH:mm"
                // placeholderText="Wählen Sie ein Datum"
                onChange={time => this.handleTimeChange(time)}
                showDisabledMonthNavigation
                showTimeSelect
                showTimeSelectOnly
                dateFormat="H:mm"
                timeCaption="Zeit"
              />
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-5 p-r-0 input-options">
            <div className="row">
              <div className="col-md-6 p-r-0 filter-groupname">
                <div className="form-group">
                  <select
                    value={defaultGroup}
                    className="form-control filterGroup"
                    onChange={e => this.handleChange(e)}
                  >
                    <option value="">Filtern nach Gruppennamen</option>
                    {listGroups.map(({ _id, generatedName }) => {
                      return (
                        <option key={_id} value={generatedName}>
                          {generatedName}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    type="button"
                    className="react-datepicker__close-icon"
                    tabIndex="-1"
                    onClick={() => this.resetGroupName()}
                  />
                </div>
              </div>
              <div className="col-md-6 p-r-0 search-users">
                <input
                  className="form-control"
                  placeholder="Suchen"
                  value={search}
                  onChange={e => this.handleInputChange(e)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="ax-table-wrapper ax-middle-text">
          <Modal
            isOpen={modalIsOpen}
            ariaHideApp={false}
            onRequestClose={() => this.closeModal()}
            style={customStyles}
            contentLabel="Delete user"
          >
            <div>
              <h4>Fremdarbeiter löschen</h4>
              <p>Wollen Sie den Fremdarbeiter wirklich löschen?</p>
              <button
                type="button"
                className="btnModalDelete"
                onClick={() => this.confirmDeleteion()}
              >
                Löschen
              </button>
              <button
                type="button"
                className="btnModalCancel"
                onClick={() => this.closeModal()}
              >
                Abbrechen
              </button>
            </div>
          </Modal>
          <MDBTable responsive bordered>
            <MDBTableHead style={{ background: '#f0f0f0' }}>
              <tr>
                <th
                  className="m-w-7 pointer"
                  onClick={e => this.onSort(e, 'surname')}
                >
                  Nachname
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th
                  className="m-w-66 pointer"
                  onClick={e => this.onSort(e, 'name')}
                >
                  Vorname
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>

                <th
                  className="m-w-6 pointer"
                  onClick={e => this.onSort(e, 'birthDate')}
                >
                  Geburts Datum
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th
                  className="m-w-6 pointer"
                  onClick={e => this.onSort(e, 'company')}
                >
                  Firma
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th
                  className="m-w-63 pointer"
                  onClick={e => this.onSort(e, 'position')}
                >
                  Funktion
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th
                  className="m-w-7 pointer"
                  onClick={e => this.onSort(e, 'sachbearbeiter')}
                >
                  Sach bearbeiter
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th
                  className="m-w-66 pointer"
                  onClick={e => this.onSort(e, 'entranceDate')}
                >
                  Zutritt ab
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th
                  className="m-w-90 pointer"
                  onClick={e => this.onSort(e, 'launchDate')}
                >
                  Einführungs Datum
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th
                  className="m-w-5 pointer"
                  onClick={e => this.onSort(e, 'time')}
                >
                  Zeit
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th
                  className="m-w-55 pointer"
                  onClick={e => this.onSort(e, 'generatedName')}
                >
                  Gruppe
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th
                  className="m-w-48 pointer"
                  onClick={e => this.onSort(e, 'zone')}
                >
                  Zone
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th
                  className="m-w-63 pointer"
                  onClick={e => this.onSort(e, 'task')}
                >
                  Auftrag
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th
                  className="m-w-6 pointer"
                  onClick={e => this.onSort(e, 'result.date')}
                >
                  Datum Versuch
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th
                  className="m-w-6 pointer"
                  onClick={e => this.onSort(e, 'score')}
                >
                  Quote Versuch
                  <i aria-hidden="true" className="fa fa-sort float-right" />
                </th>
                <th>Aktionen</th>
              </tr>
            </MDBTableHead>
            {users[0] ? (
              <MDBTableBody>
                {users.map(user => (
                  <tr key={user._id}>
                    <td>{user.surname}</td>
                    <td>{user.name}</td>
                    <td>{formatDate(user.birthDate)}</td>
                    <td>{user.company}</td>
                    <td>{user.position}</td>
                    <td>{user.sachbearbeiter}</td>
                    <td>{formatDate(user.entranceDate)}</td>
                    <td>{formatDate(user.launchDate)}</td>
                    <td>{user.time}</td>
                    <td>{user.generatedName}</td>
                    <td>{user.zone ? 'Ja' : 'Nein'}</td>
                    <td>{user.task}</td>
                    <td>
                      {user.result.date ? formatDate(user.result.date) : '-'}
                    </td>
                    {this.calculatePercentage(
                      user.result.score,
                      user.result.decision,
                      user.result.orange
                    )}
                    <td className="button-table ">
                      <td className="table-border">
                        <Link
                          className="btn btn-brand customBTNE"
                          to={`${routeEndpoint}users/${user._id}`}
                        >
                          <i className="far fa-edit icon-edit"></i>
                        </Link>
                      </td>
                      <td className="table-border">
                        <button
                          className="btn btn-brand customBTND"
                          type="button"
                          onClick={() => this.deleteUser(user._id)}
                        >
                          <i className="fas fa-trash-alt icon-del"></i>
                        </button>
                      </td>
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            ) : (
              <MDBTableBody>
                <tr>
                  <td colSpan="14">{msgTable}</td>
                </tr>
              </MDBTableBody>
            )}
          </MDBTable>
          <div className="row">
            <div className="col-sm-12 col-md-5">
              <div className="dataTables_info" role="status" aria-live="polite">
                Showing 1 to 1 of 1 entries
              </div>
            </div>
            <div className="col-sm-12 col-md-7">
              <div className="pagination-buttons pull-right m-t-25 m-b-10">
                <button
                  disabled={pageOffset === 0}
                  onClick={() => {
                    this.pageOffset('previous', paginationType);
                  }}
                  className="btn b-r-0"
                  type="button"
                >
                  Vorherige
                </button>
                <button
                  className="btn btn-brand b-r-0 not-clickable"
                  type="button"
                >
                  {pageNr}
                </button>
                <button
                  disabled={disabled}
                  onClick={() => {
                    this.pageOffset('next', paginationType);
                  }}
                  className="btn b-r-0"
                  type="button"
                >
                  Nächste
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Users;
