/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import toaster from '../../utils/toaster';
import './attemptResult.less';
import { saveDecisionComment } from '../../services/users.service';
import { formatDate } from '../../utils/formatDate';
import { routeEndpoint } from '../../services/api';

class AttemptResult extends Component {
  constructor(props) {
    super(props);
    const { userResults } = this.props;
    const { decision, comment, CreatedOn } = userResults;
    this.state = {
      decision,
      comment,
      createdOn: formatDate(CreatedOn),
    };
  }

  setDecision(dc) {
    const { decision } = this.state;
    this.setState({
      decision: dc === decision ? '' : dc,
    });
  }

  onSave = () => {
    const { userResults, match, history } = this.props;
    const { modules } = userResults;
    const { userId, attemptId } = match.params;
    const { decision, comment } = this.state;

    saveDecisionComment(userId, modules, attemptId, decision, comment).then(
      () => {
        toaster('Entscheidung wurde erfolgreich gespeichert!', 'success');
        history.push(`${routeEndpoint}users/${userId}`);
      },
      () => {
        toaster('Ein Fehler ist aufgetreten!', 'error');
      }
    );
  };

  handleChange(e) {
    this.setState({
      comment: e.target.value,
    });
  }

  render() {
    const { userResults, match, wrongQuestion } = this.props;
    const { decision, comment, createdOn } = this.state;

    let fromone = 0;
    return (
      <React.Fragment>
        <div className="m-t-40">
          <h6 className="bold ax-module-title">Ergebnis pro Modul</h6>
          <p>
            <span className="semi-bold ax-title">Durchführungsdatum: </span>
            {createdOn}
          </p>
        </div>
        <div className="m-t-30">
          <table className="full-width">
            <thead>
              <tr>
                <th>
                  <h6 className="bold ax-module-title">Modul</h6>
                </th>
                <th>
                  <h6 className="bold ax-module-title">Ergebnis in %</h6>
                </th>
                <th>
                  <h6 className="bold ax-module-title">Ergebnis</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              {userResults.modules.map(result => (
                <tr key={result.module}>
                  <td className="semi-bold ax-title">{result.module}</td>
                  <td className="semi-bold ax-title">
                    {Math.floor(result.percentageResult)}%
                  </td>
                  <td className="semi-bold ax-title">
                    {result.correctQuestions} von {result.totalQuestions} Fragen
                    korrekt beantwortet.
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {wrongQuestion && wrongQuestion.length > 0 && (
            <div className="m-t-30">
              <h6 className="bold ax-module-title">
                Falsch beantwortete Fragen
              </h6>
              <ul className="wrquestion">
                {wrongQuestion.map((wrquestion, indx) => {
                  fromone++;
                  return (
                    <li key={indx}>
                      {wrquestion.questionKey}: {wrquestion.text}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className="row m-t-30">
          {userResults.decision === 'Zulass gewähren' ||
          userResults.decision === 'Zulass ablehnen' ? (
            <div className="col-md-12">
              <h6>
                <span className="bold">Entscheidung: </span>
                {userResults.decision}
              </h6>
            </div>
          ) : (
            <div className="col-md-12">
              <button
                type="button"
                onClick={this.setDecision.bind(this, 'Zulass gewähren')}
                className={`btn btn-cons ax-btn-large ${
                  decision === 'Zulass gewähren'
                    ? 'ax-btn-success btn-success'
                    : 'btn-success'
                }`}
              >
                Zulass gewähren
              </button>
              <button
                type="button"
                onClick={this.setDecision.bind(this, 'Zulass ablehnen')}
                className={`btn btn-cons ax-btn-large ${
                  decision === 'Zulass ablehnen'
                    ? 'ax-btn-danger btn-danger'
                    : 'btn-danger'
                }`}
              >
                Zulass ablehnen
              </button>
            </div>
          )}
        </div>
        <div className="row m-t-30 col-md-6">
          <div className="form-group form-group-default">
            {userResults.decision === 'Zulass gewähren' ||
            userResults.decision === 'Zulass ablehnen' ? (
              <label htmlFor="comment">
                Kommentar
                <textarea
                  defaultValue={comment}
                  className="form-control"
                  name="projectName"
                  id="comment"
                  cols="30"
                  rows="10"
                  disabled
                />
              </label>
            ) : (
              <label htmlFor="comment">
                Kommentar
                <textarea
                  className="form-control"
                  name="projectName"
                  id="comment"
                  cols="30"
                  rows="10"
                  value={comment}
                  onChange={this.handleChange.bind(this)}
                />
              </label>
            )}
          </div>
        </div>
        <div className="row m-t-30 ">
          <div className="col-md-4">
            {userResults.decision === 'Zulass gewähren' ||
            userResults.decision === 'Zulass ablehnen' ? (
              ''
            ) : (
              <button
                type="button"
                className="btn btn-brand btn-cons"
                onClick={this.onSave}
              >
                Speichern
              </button>
            )}
            <Link
              className="btn btn-brand btn-cons"
              to={`${routeEndpoint}users/${match.params.userId}`}
            >
              Zurück
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AttemptResult;
