import React from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { NavLink } from 'react-router-dom';
import { routeEndpoint } from '../../services/api';
import './breadcrumb.less';

const routes = [
  { path: '/axpo', breadcrumb: 'Axpo' },
  { path: '/users', breadcrumb: 'Fremdpersonal' },
  { path: '/classList', breadcrumb: 'Klassenliste' },
  { path: '/create', breadcrumb: 'Benutzer Anlegen' },
  { path: '/classList/classListTable', breadcrumb: 'Klassenliste Tabelle' },
  { path: '/users/:userId', breadcrumb: 'Profil' },
  { path: '/users/:userId/:attemptId', breadcrumb: 'Versuch' },
];

const Breadcrumbs = withBreadcrumbs(routes)(({ breadcrumbs }) => {
  return (
    <div className="jumbotron ax-jumbotron" data-pages="parallax">
      <div className="container-fluid container-fixed-lg sm-p-l-0 sm-p-r-0">
        <div className="inner">
          <ol className="ax-breadcrumb breadcrumb">
            {breadcrumbs.map(({ match, breadcrumb }) => {
              if (routeEndpoint === '/axpo/' && match.url === '/') {
                return true;
              }
              return (
                <li
                  className="ax-breadcrumb-item breadcrumb-item"
                  key={match.url}
                >
                  <NavLink to={match.url}>{breadcrumb}</NavLink>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
});

export default Breadcrumbs;
