import React from 'react';
import ReactDOM from 'react-dom';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';

// components
import MainLayout from './containers/mainLayout/mainLayout';
import LoginLayout from './containers/loginLayout/loginLayout';
import Login from './components/login/login';
import Home from './containers/home/home';
import Users from './containers/users/users';
import UserEdit from './containers/userEdit/userEdit';
import UserCreate from './containers/userCreate/userCreate';
import UserResult from './containers/userResult/userResult';
import ClassList from './containers/classList/classList';
import ClassListTable from './containers/classListTable/classListTable';
import UserGroup from './containers/userGroup/userGroup';
import NotFound from './containers/404/notfound';
import ServerError from './containers/500/serverError';

import { isLoggedIn, routeEndpoint } from './services/api';

import './style/main.less';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const isUserLoggedIn = isLoggedIn();
  // const isSavedCredentials = localStorage.getItem('saveCredentials');
  return (
    <Route
      {...rest}
      render={props =>
        isUserLoggedIn ||
        props.location.pathname === `${routeEndpoint}login` ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: `${routeEndpoint}login`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const App = () => {
  return (
    <Router>
      <Switch>
        <AppRoute
          exact
          path={`${routeEndpoint}`}
          layout={MainLayout}
          component={Home}
        />

        <AppRoute
          exact
          path={`${routeEndpoint}users`}
          layout={MainLayout}
          component={Users}
        />
        <AppRoute
          exact
          path={`${routeEndpoint}users/:userId`}
          layout={MainLayout}
          component={UserEdit}
        />
        <AppRoute
          exact
          path={`${routeEndpoint}users/:userId/userGroup`}
          layout={MainLayout}
          component={UserGroup}
        />
        <AppRoute
          exact
          path={`${routeEndpoint}create`}
          layout={MainLayout}
          component={UserCreate}
        />
        <AppRoute
          path={`${routeEndpoint}users/:userId/:attemptId`}
          layout={MainLayout}
          component={UserResult}
        />
        <AppRoute
          exact
          path={`${routeEndpoint}classList`}
          layout={MainLayout}
          component={ClassList}
        />
        <AppRoute
          exact
          path={`${routeEndpoint}classList/classListTable`}
          layout={MainLayout}
          component={ClassListTable}
        />
        <AppRoute
          exact
          path={`${routeEndpoint}login`}
          layout={LoginLayout}
          component={Login}
        />
        <Route exact path={`${routeEndpoint}error`} component={ServerError} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

module.hot.accept();
