export const required = fieldValue =>
  !fieldValue ? 'Bitte füllen Sie das Feld aus' : undefined;

export const lessThanValue = value => fieldValue =>
  fieldValue.length < value
    ? undefined
    : `Der Wert darf maximal ${value} Zeichen enthalten`;

export const greaterThanField = fieldName => (fieldValue, state) =>
  fieldValue > state[fieldName]
    ? undefined
    : `Der Wert muss mindestens ${fieldName} Zeichen enthalten`;

export const validEmail = fieldValue => {
  const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );
  return emailRegex.test(fieldValue) ? undefined : `Ungültige E-Mail-Adresse`;
};

export const isOnlyNumbers = fieldValue => {
  const numberRegex = RegExp(/^\d+$/);
  return numberRegex.test(fieldValue) ? undefined : `Nur Zahlen sind erlaubt`;
};
