import React, { Component } from 'react';
import { getUserData } from '../../services/auth.service';
import './home.less';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: '',
    };
  }

  componentDidMount() {
    const userData = getUserData();
    this.setState({ userRole: userData.role });
  }

  render() {
    const { userRole } = this.state;

    return (
      <div>
        <h4 className="ax-header-text">Dashboard</h4>
        {userRole === 'fpk' ? (
          <React.Fragment>
            <p>
              Herzlich Willkommen im Lernkontrolle Tool für
              Fremdpersonalkoordinator .
            </p>
            <p>
              Mit diesem Tool überprüfen Sie das Wissen vom Fremdpersonal über
              die Anlage und erteilen je nach Resultat die Freigabe zur
              Erstellung der Zutrittsausweise für das KKB Beznau.
            </p>
            <p>
              Sie finden links die Rubrik Fremdpersonal. Dort sehen Sie
              diejenigen Fremdpersonen, die vom Sachbearbeiter angemeldet
              wurden. Die Lernmodule werden über eine APP mit personalisiertem
              Login der Fremdperson zur Verfügung gestellt. Diese Login-Daten
              sind dem Fremdpersonal bereits zugestellt worden. Die Login-Daten
              finden Sie beim jeweiligem Fremdpersonal-Profil.
            </p>
            <p>
              Nachdem die Fremdperson die Lernmodule abgearbeitet hat, sehen Sie
              die Resultate beim jeweiligen Benutzerprofil in diesem Tool.
            </p>
            <p>
              Sie entscheiden nun, ob Sie die Fremdperson freigeben für die
              Erstellung des Zutritts zur Anlage. Bei ungenügendem Resultat kann
              die Fremdperson den Test mit den gleichen Login-Daten innerhalb
              des selben Tages wiederholen. Sämtliche Versuche werden
              protokolliert und sind im jeweiligen Benutzerprofil einsehbar.
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p>Herzlich Willkommen im Lernkontrolle Tool für Sachbearbeiter.</p>
            <p>
              Mit diesem Tool erstellen, planen und schulen Sie das Wissen von
              Fremdpersonal über die Anlage, wenn Sie Fremdarbeiter im KKB
              Beznau einsetzen.
            </p>
            <p>
              Sie finden links die Rubrik Fremdpersonal. Hier teilen Sie den
              gewählten Fremdpersonen die für den jeweiligen Einsatz notwendigen
              Lernmodule zu. Die Lernmodule werden der Fremdperson über eine APP
              mit personalisiertem Login zur Verfügung gestellt. Die Login-Daten
              stellen Sie als Sachbearbeiter per Mail oder als PDF zur
              Verfügung.
            </p>
            <p>
              Nach Abschluss der Zuteilung und nach der Zustellung der
              Login-Daten übernimmt das Fremdpersonalkoordinator -Team die
              weiteren Schritte.
            </p>
            <p>
              Wenn sich die Fremdperson erfolgreich durch die Lerninhalte
              gearbeitet hat, sehen Sie in der jeweiligen Übersicht die
              Zutritts-Entscheidung des Frempersonalkoordinations-Team.
            </p>
          </React.Fragment>
        )}
        <p>
          Bei Fragen kontaktieren Sie bitte Ihren Vorgesetzen oder die
          Support-Firma:
          <br /> PRISMA Videoproduktionen und Systeme AG <br />
          <a href="tel:+41812571010">+41 81 257 10 10 </a>
        </p>
      </div>
    );
  }
}

export default Home;
