import decode from 'jwt-decode';
import { fetch } from './api';

const getRememberMe = () => {
  return localStorage.getItem('saveCredentials') === 'true';
};

const setToken = token => {
  // Saves user token to localStorage or sessionStorage
  if (getRememberMe()) {
    return localStorage.setItem('token', token);
  }
  return sessionStorage.setItem('token', token);
};

export const getToken = () => {
  // Retrieves the user token from localStorage
  if (getRememberMe()) {
    return localStorage.getItem('token');
  }
  return sessionStorage.getItem('token');
};

export const login = (username, password, saveCredentials) => {
  return new Promise((resolve, reject) => {
    fetch
      .post('/user/login', { username, password, isAdmin: true })
      .then(({ token }) => {
        if (token) {
          localStorage.setItem('saveCredentials', saveCredentials);
          setToken(token);
          resolve(token);
        }
      })
      .catch(error => {
        // console.log('SSSS', error.response);
        // console.log(error);
        reject(error.message);
      });
  });
};

export const logout = () => {
  // Clear user token and profile data from localStorage
  if (getRememberMe()) {
    return localStorage.removeItem('token');
  }
  return sessionStorage.removeItem('token');
};

export const getUserData = () => {
  // Using jwt-decode npm package to decode the token
  const user = decode(getToken());
  console.log('Recieved answer!');
  return user;
};
