import React from 'react';
import './loginLayout.less';
import '../../style/main.css';

const LoginLayout = ({ children }) => (
  <React.Fragment>
    <div className="login-wrapper">
      {/* <!-- START Login Background Pic Wrapper--> */}
      <div className="row login-img-wrapper">
        <div className="col-md-8 p-r-0">
          {/* <!-- START Background Pic--> */}
          <div className="login-img">
            <div className="overlay-img" />
          </div>
          {/* <!-- END Background Pic--> */}
        </div>
        {/* <!-- START Login Right Container--> */}
        <div className="col-md-4 p-l-0 login-container bg-white">
          <div className="login-content p-l-50 p-r-50 p-t-50 m-l-20 m-r-20 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
            <h2 className="ax-header-text">Adminbereich</h2>
            <p className="p-t-15 ax-midle-text">
              Willkommen im Backoffice der AXPO E-Learning App
            </p>
            <p className="p-t-15 ax-midle-text">
              Bitte verwenden Sie die Logindaten welche Ihnen zugestellt wurden.
              Bei Problemen klicken Sie bitte auf den Link unterhalb der
              Loginfelder
            </p>
            <p className="p-t-35 p-b-35 ax-midle-text">
              Geben Sie Ihre Anmeldeninformationen ein
            </p>
            {children}
            {/* <!-- START Login Form --> */}
          </div>
        </div>
      </div>
      {/* <!-- END Login Right Container--> */}
    </div>
  </React.Fragment>
);

export default LoginLayout;
