import React, { Component } from 'react';
import { MDBDataTable } from 'mdbreact';
// import { Link } from 'react-router-dom';
import { getUser } from '../../services/users.service';
import { formatDate } from '../../utils/formatDate';
import { checkDecision, checkPDF, checkButton } from '../../utils/conditions';
import './userResults.less';

class UserResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          label: 'Datum',
          field: 'date',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Versuch',
          field: 'attempt',
          sort: 'asc',
          width: 150,
        },
        {
          label: 'Aufgabe',
          field: 'task',
          sort: 'asc',
          width: 300,
        },
        {
          label: 'Entscheidung',
          field: 'decision',
          sort: 'asc',
          width: 200,
        },
        {
          label: 'Einlass PDF',
          field: 'attemptPdf',
          sort: 'asc',
          width: 70,
        },
        {
          label: 'Auswahl',
          field: 'edit',
          sort: 'disabled',
          width: 150,
        },
      ],
      rows: [],
    };
  }

  componentDidMount() {
    const { match } = this.props;

    getUser(match.params.userId).then(user => {
      const allResults = user.result.map(result => {
        const items = {
          date: formatDate(result.CreatedOn),
          attempt: result.attempt,
          task: result.task,
          decision: checkDecision(result.decision),
          attemptPdf: checkPDF(result.decision, result.pdfLink),
          edit: checkButton(result.decision, match.url, result._id),
        };
        return items;
      });
      this.setState({ rows: allResults });
    });
  }

  render() {
    const { columns, rows } = this.state;

    return (
      <div className="row">
        <div className="col-md-12 col-xs-12">
          <h5 className="ax-middle-title">Auswertung</h5>
          <p className="ax-title">
            Untenstehend findet sich eine Auflistung der zuletzt durchgeführten
            Tests.
          </p>
          <div className="ax-table-wrapper">
            {rows.length > 0 ? (
              <MDBDataTable
                responsive
                bordered
                searchLabel="Suche"
                paginationLabel={['Vorherige', 'Nächste']}
                data={{ columns, rows }}
              />
            ) : (
              <div className="jumbotron jumbotron-fluid d-flex justify-content-center ax-empty">
                <p className="ax-empty-msg m-t-30">Noch keine Versuche.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default UserResults;
