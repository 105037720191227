export const formatDate = date => {
  const formatedDate = new Date(date);
  const day = `0${formatedDate.getUTCDate()}`.slice(-2);
  const month = `0${formatedDate.getUTCMonth() + 1}`.slice(-2);
  return `${day}.${month}.${formatedDate.getUTCFullYear()}`;
};

export const newformatDate = date => {
  const formatedDate = new Date(date);
  const day = `0${formatedDate.getUTCDate() + 1}`.slice(-2);
  const month = `0${formatedDate.getUTCMonth() + 1}`.slice(-2);
  return `${day}.${month}.${formatedDate.getUTCFullYear()}`;
};

export const formatWithoutDots = date => {
  const formatedDate = new Date(date);
  const day = `0${formatedDate.getUTCDate()}`.slice(-2);
  const month = `0${formatedDate.getUTCMonth() + 1}`.slice(-2);
  return `${day}${month}${formatedDate.getUTCFullYear()}`;
};

export const formatTime = date => {
  const formatedDate = new Date(date);
  return `${formatedDate.getHours()}:${formatedDate.getMinutes()}`;
};

export const formatUSDate = date => {
  const formatedDate = new Date(date);
  const day = `0${formatedDate.getUTCDate()}`.slice(-2);
  const month = `0${formatedDate.getUTCMonth() + 1}`.slice(-2);
  return `${formatedDate.getUTCFullYear()}.${month}.${day}`;
};

export const formatUSDateWithLine = date => {
  const formatedDate = new Date(date);
  const day = `0${formatedDate.getUTCDate()}`.slice(-2);
  const month = `0${formatedDate.getUTCMonth() + 1}`.slice(-2);
  return `${formatedDate.getUTCFullYear()}-${month}-${day}`;
};

export const newformatUSDateWithLine = date => {
  const formatedDate = new Date(date);
  const day = `0${formatedDate.getUTCDate() + 1}`.slice(-2);
  const month = `0${formatedDate.getUTCMonth() + 1}`.slice(-2);
  return `${formatedDate.getUTCFullYear()}-${month}-${day}`;
};
