import React from 'react';
import { NavLink } from 'react-router-dom';
import './menu.less';
import { routeEndpoint } from '../../services/api';

const toggleSidebar = () => {
  const body = document.getElementById('ax-body');
  const pageSidebar = document.getElementById('ax-page-sidebar');
  pageSidebar.classList.remove('visible');
  body.classList.remove('sidebar-open');
};

const Menu = () => {
  return (
    <div className="sidebar-menu ax-menu">
      <ul className="ax-menu-icons menu-items scroll-content ">
        <li className="m-t-30">
          <NavLink
            exact
            activeClassName="navbar__link--active"
            className="navbar__link"
            to={`${routeEndpoint}`}
            onClick={toggleSidebar}
          >
            <span className="ax-menu-item">Home</span>
          </NavLink>
          <span className="icon-thumbnail ">
            <i className="fa fa-home" />
          </span>
        </li>
        <li>
          <NavLink
            exact
            activeClassName="navbar__link--active"
            className="navbar__link"
            to={`${routeEndpoint}users`}
            onClick={toggleSidebar}
          >
            <span className="ax-menu-item">Fremdpersonal</span>
          </NavLink>
          <span className="icon-thumbnail ">
            <i className="fa fa-users " />
          </span>
        </li>
        <li>
          <NavLink
            exact
            activeClassName="navbar__link--active"
            className="navbar__link"
            to={`${routeEndpoint}classList`}
            onClick={toggleSidebar}
          >
            <span className="ax-menu-item">Import Klassenliste</span>
          </NavLink>
          <span className="icon-thumbnail ">
            <i className="fas fa-cloud-download-alt" />
          </span>
        </li>
      </ul>
      <div className="clearfix" />
    </div>
  );
};

export default Menu;
